import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/Layout";
import { HTMLContent } from "../components/common/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faNpm } from "@fortawesome/free-brands-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";

// props
interface Props {
  data: {
    page: {
      html: any;
      frontmatter: {
        title: string;
        description?: string;
        keywords?: Array<string>;
        slug: string;
      };
    };
    projects: {
      edges: Array<{
        node: {
          id: string;
          name: string;
          github: string;
          description: string;
          version?: string;
          release?: string;
          reference?: Array<{
            label: string;
            link?: string;
          }>;
          versions?: Array<{
            github: string;
            label: string;
            releaseTrain: string;
          }>;
          javadoc?: Array<{
            label: string;
            link?: string;
          }>;
        };
      }>;
    };
  };
}

const Label = ({ item }: any) => {
  if (item?.link) {
    return (
      <a className="px-3" href={item.link}>
        {item.label}
      </a>
    );
  }
  return <span className="px-1">{item.label}</span>;
};

const Item = ({ project }: any) => {
  return (
    <article className="py-6 has-border-top">
      <h1 className="is-size-4 m-0 p-0 pb-3">{project.name}</h1>
      <div className="columns">
        <div className="column is-5">
          <p className="m-0 p-0">{project.description}</p>
          {project.version && (
            <p className="m-0 p-0">
              <span className="tag is-success">{project.version}</span>
            </p>
          )}
          {project.versions?.length > 0 && (
            <table className="table is-bordered my-3">
              <thead>
                <tr>
                  <th>Version</th>
                  <th>
                    {project.release && <a href={project.release}>Releases</a>}
                  </th>
                  <th>Release Train</th>
                </tr>
              </thead>
              <tbody>
                {project.versions.map((version: any, index: number) => (
                  <tr key={`tr${index}`}>
                    <td key={`td1${index}`}>
                      <span className="tag is-success">{version.label}</span>
                    </td>
                    <td key={`td2${index}`}>
                      <a key={`a${index}`} href={version.github}>
                        Release
                      </a>
                    </td>
                    <td key={`td3${index}`}>
                      <span className="tag is-black">
                        {version.releaseTrain}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="column">
          <div className="columns">
            <div className="column is-2 has-text-centered">
              <a
                href={project.github}
                className="has-text-centered is-inline-block"
              >
                <FontAwesomeIcon icon={faGithub} size="2x" />
                <br />
                <span>Github</span>
              </a>
            </div>
            {project.javadoc && (
              <div className="column is-5 has-text-centered">
                Javadoc
                <br />
                <div className="has-a-sperator">
                  {project.javadoc.map((item: any, index: number) => (
                    <Label key={`javadoc${index}`} item={item} />
                  ))}
                </div>
              </div>
            )}
            {project.reference && (
              <div className="column is-5 has-text-centered">
                Reference
                <br />
                <div className="has-a-sperator">
                  {project.reference.map((item: any, index: number) => (
                    <Label key={`ref${index}`} item={item} />
                  ))}
                </div>
              </div>
            )}
            {project.npm && (
              <div className="column is-2 has-text-centered">
                <a
                  href={project.npm}
                  className="has-text-centered is-inline-block"
                >
                  <FontAwesomeIcon icon={faNpm} size="2x" />
                  <br />
                  <span>NPM</span>
                </a>
              </div>
            )}
            {project.nuget && (
              <div className="column is-2 has-text-centered">
                <a
                  href={project.nuget}
                  className="has-text-centered is-inline-block"
                >
                  <FontAwesomeIcon icon={faBook} size="2x" />
                  <br />
                  <span>NUGET</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

// markup
const DocumentationTemplate = (props: Props) => {
  const { projects, page } = props.data;
  return (
    <Layout className={`page documentation`}>
      <div className="extra">
        <img className="img-light" src="/img/extra/quickstart.svg" alt="" />
        <img className="img-dark" src="/img/extra/quickstart-dark.svg" alt="" />
      </div>
      <div className="container">
        <div className="content markdown">
          <div className="is-max-600 py-6">
            <h1 className="is-size-2 m-0 p-0">{page.frontmatter.title}</h1>
            <p className="mt-1 pb-3">
              Project Reactor reference documentation and Javadoc.
            </p>
            <div className="p-5 has-background-light">
              <ul className="mt-0">
                <li>
                  Latest stable BOM:{" "}
                  <span className="tag is-black">2022.0.7 (F)</span>
                </li>
                <li>
                  Latest BOMs in previous release trains:{" "}
                  <span className="tag is-black mr-2">2020.0.32</span>
                  <span className="tags my-0 has-addons is-inline mr-2">
                    <span className="tag my-0 is-danger">EOL</span>
                    <span className="tag is-black my-0">Dysprosium-SR25</span>
                  </span>
                  <span className="tags has-addons is-inline mr-2">
                    <span className="tag my-0 is-danger">EOL</span>
                    <span className="tag is-black my-0">Californium-SR23</span>
                  </span>
                  <span className="tags my-0 has-addons is-inline mr-2">
                    <span className="tag my-0 is-danger">EOL</span>
                    <span className="tag is-black my-0">Bismuth-SR17</span>
                  </span>
                  <span className="tags my-0 has-addons is-inline mr-2">
                    <span className="tag my-0 is-danger">EOL</span>
                    <span className="tag is-black my-0">Aluminium-SR3</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="py-5">
            {projects.edges.map((project, index) => (
              <Item key={index} project={project.node}></Item>
            ))}
          </div>
        </div>
      </div>
      <div className="content has-background-light">
        <div className="container py-5">
          <h2 className="is-size-5">Legend</h2>
          <ul>
            <li>
              <span className="tags has-addons my-0 is-inline mr-2">
                <span className="tag my-0 is-danger">WIP</span>
                <span className="tag my-0 is-black">RELEASE-TRAIN</span>
              </span>
              : Artifacts in these release trains have not all been released
              (e.g.{" "}
              <span className="tags has-addons my-0 is-inline">
                <span className="tag my-0 is-danger">WIP</span>
                <span className="tag my-0 is-black">Californium-SR30</span>
              </span>
              ).
            </li>
            <li>
              <span className="tag is-black">RELEASE-TRAIN</span>: Artifacts in
              the latest stable and fully available release train (currently{" "}
              <span className="tag is-black">2022.0.7</span>).
            </li>
            <li>
              <span className="tags has-addons is-inline my-0 mr-2">
                <span className="tag my-0 is-link">PRE</span>
                <span className="tag my-0 is-black">RELEASE-TRAIN</span>
              </span>
              : Artifacts in the latest pre-release release train ( e.g.
              <span className="tags has-addons my-0 is-inline">
                <span className="tag my-0 is-link">PRE</span>
                <span className="tag my-0 is-black">Californium-M1</span>
              </span>
              ).
            </li>
            <li>
              <span className="tag is-success">version</span>: Available RELEASE
              artifacts.
            </li>
            <li>
              <span className="tag is-link">version</span>: Available MILESTONE
              or other pre-release artifacts (available from repo.spring.io).
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default DocumentationTemplate;

// graphQL Index page queries
export const pageQuery = graphql`
  query DocumentationTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        slug
      }
    }
    projects: allProjectsJson {
      edges {
        node {
          id
          name
          github
          description
          version
          npm
          nuget
          release
          reference {
            label
            link
          }
          versions {
            github
            label
            releaseTrain
          }
          javadoc {
            label
            link
          }
        }
      }
    }
  }
`;
